<template>
  <div class="case-list">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/project'}">项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>测试用例</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <div class="case-header" slot="header">
        <div>
          <el-button round icon="el-icon-d-arrow-left" @click="goHome">返回</el-button>
          <el-button type="warning" icon="el-icon-plus" @click="deBugCase">执行用例</el-button>
          <el-button type="warning" icon="el-icon-caret-right" @click="handleAdd">新增用例</el-button>
          <el-upload
            class="upload-case"
            action="https://jsonplaceholder.typicode.com/posts/"
            :http-request="handleDelUpload"
            :show-file-list="false">
            <el-button type="warning" icon="el-icon-upload2">导入用例</el-button>
          </el-upload>
          <el-button type="warning" icon="el-icon-download" @click="handleExport">导出用例</el-button>
        </div>
        <el-form :inline="true" :model="caseForm" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="caseForm.caseName" placeholder="输入测试用例名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 显示测试用例内容 -->
      <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="id" label="用例ID"></el-table-column>
      <el-table-column prop="case_name" label="用例名称"></el-table-column>
      <el-table-column prop="description" label="用例描述" width="180"></el-table-column>
      <el-table-column prop="method" label="请求方式"></el-table-column>
      <el-table-column prop="protocal" label="请求协议"></el-table-column>
      <el-table-column prop="path" label="请求路径" width="180"></el-table-column>
      <el-table-column prop="isDeleted" label="用例状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button round type="warning" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button round type="danger" @click="handleDel(scope.row)">删除</el-button>
          <el-button round type="info" @click="handleDis(scope.row)">{{ scope.row.status === '1' ? '禁用' : '启用' }}</el-button>
        </template>
      </el-table-column>
      </el-table>

    </el-card>

    <!-- 测试用例分页 -->
    <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="pageInfo.currentPage"
    :page-sizes="[5, 10, 30, 50]"
    :page-size="pageInfo.size"
    layout="total, sizes, prev, pager, next, jumper"
    :total="pageInfo.total_count">
    </el-pagination>

    <!-- 新增测试用例 dialog -->
    <el-dialog title="接口信息" :visible.sync="dialogFormVisible" width="55%">
      <el-form :model="dialogCaseForm" ref="dialogCaseFormRef">
        <el-form-item label="operation" prop="operation" :label-width="formLabelWidth">
          <el-input v-model="dialogCaseForm.operation" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="接口名称" :label-width="formLabelWidth">
          <el-input v-model="dialogCaseForm.case_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="请求协议" :label-width="formLabelWidth">
          <el-select v-model="dialogCaseForm.request_protocal" placeholder="请选择请求协议">
            <el-option label="http" value="http"></el-option>
            <el-option label="https" value="https"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请求IP" :label-width="formLabelWidth">
          <el-input v-model="dialogCaseForm.request_host" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="请求端口" :label-width="formLabelWidth">
          <el-input v-model="dialogCaseForm.request_port" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="接口路由" :label-width="formLabelWidth">
          <el-input v-model="dialogCaseForm.request_path" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="请求方式" :label-width="formLabelWidth">
          <el-select v-model="dialogCaseForm.request_method" placeholder="请选择请求方式">
            <el-option label="GET" value="GET"></el-option>
            <el-option label="POSt" value="POST"></el-option>
            <el-option label="PUT" value="PUT"></el-option>
            <el-option label="DELETE" value="DELETE"></el-option>
            <el-option label="PATCH" value="PATCH"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请求params参数" :label-width="formLabelWidth">
          <el-input v-model="dialogCaseForm.request_params" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogCaseForm.request_method !== 'GET'" label="请求body参数" :label-width="formLabelWidth">
          <el-input v-model="dialogCaseForm.request_body" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="断言数据" :label-width="formLabelWidth">
          <el-input v-model="dialogCaseForm.predict" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="接口描述" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="dialogCaseForm.description" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEdit">编 辑</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { QueryCaseList, DelCase, AddCase, SearchCase, DisCase, EditCase, ImportCase, ExportCase, RunCase } from '@/services/case.js'
export default {
  name: 'CaseList',
  data () {
    return {
      isAdd: true,
      currentId: null,
      caseForm: {
        caseName: ''
      },
      tableData: [],
      multipleSelection: [],
      multipleSelections: [],
      dialogFormVisible: false,
      pageInfo: {
        size: 10,
        currentPage: 1,
        total_count: null
      },
      dialogCaseForm: {
        operation: 'add',
        case_name: '',
        request_protocal: '',
        request_method: '',
        request_host: '',
        request_path: '',
        request_port: '',
        request_params: '',
        request_body: '',
        predict: '',
        description: ''
      },
      formLabelWidth: '120px'
    }
  },
  created () {
    this.loadCaseList()
  },
  methods: {
    // 操作当前选中的用例
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(this.multipleSelection, '<--选中的数据')
      // 清空之前的 multipleSelections 值，取消选择后重新更新数据
      this.multipleSelections = []
      // 此时 取消/选择 用例的时候，让对应的 ID 信息更新在指定的数据中
      this.multipleSelection.map((item) => {
        // 每次存储数据的时候需要判断当前的 ID 是否已经存在
        if (!this.multipleSelections.includes(item.id)) {
          this.multipleSelections.push(item.id)
        }
      })
      console.log(this.multipleSelections, '<--要传入的测试用例ID列表')
    },
    // 测试用例信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadCaseList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadCaseList()
      console.log(`当前页: ${val}`)
    },
    // 查询测试用例列表
    async loadCaseList () {
      const queryObj = {
        suite_id: this.$store.state.suiteId,
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size,
        query_type: 'query_list'
      }
      console.log(queryObj, '<--用例列表请求参数')
      const { data: res } = await QueryCaseList(queryObj)
      console.log(res, '<--测试用例列表信息')
      if (res.status === 1) {
        this.tableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    // 执行当前的测试用例查询
    async handleSearch () {
      const SearchObj = {
        query_type: 'search_case',
        case_name: this.caseForm.caseName,
        suite_id: this.$store.state.suiteId
      }
      const { data: res } = await SearchCase(SearchObj)
      console.log(res, '<--查询的测试用例结果')
      if (res.status === 1) {
        this.tableData = res.data
      }
    },
    goHome () {
      this.$router.go(-1)
    },
    async handleDel (rowData) {
      this.$confirm('此操作将永久删除测试用例, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const DelObj = {
          id: rowData.id,
          suite_id: this.$store.state.suiteId
        }
        const { data: res } = await DelCase(DelObj)
        console.log(res, '<--删除对应的测试用例')
        if (res.status === 1) {
          this.$message.success('删除测试用例成功')
          this.loadCaseList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 添加测试用例 dialog 弹窗
    handleAdd () {
      this.isAdd = true
      this.dialogCaseForm.case_name = ''
      this.dialogCaseForm.request_protocal = ''
      this.dialogCaseForm.request_method = ''
      this.dialogCaseForm.request_host = ''
      this.dialogCaseForm.request_path = ''
      this.dialogCaseForm.request_port = ''
      this.dialogCaseForm.request_params = ''
      this.dialogCaseForm.request_body = ''
      this.dialogCaseForm.predict = ''
      this.dialogCaseForm.description = ''
      this.dialogCaseForm.operation = 'add'
      this.dialogCaseForm.suite_id = this.$store.state.suiteId
      this.dialogFormVisible = true
    },
    // 添加测试用例动作
    async processAdd () {
      try {
        // await this.$refs.dialogCaseFormRef.validate()
        const addOperation = {
          case_name: this.dialogCaseForm.case_name,
          suite_id: this.$store.state.suiteId,
          project_id: this.$store.state.projectId,
          request_protocal: this.dialogCaseForm.request_protocal,
          request_method: this.dialogCaseForm.request_method,
          request_host: this.dialogCaseForm.request_host,
          request_port: this.dialogCaseForm.request_port,
          request_path: this.dialogCaseForm.request_path,
          request_params: this.dialogCaseForm.request_params,
          request_body: this.dialogCaseForm.request_body,
          operation: 'add',
          description: this.dialogCaseForm.description
        }
        const { data: res } = await AddCase(addOperation)
        if (res.status === 1) {
          this.dialogFormVisible = false
          this.$refs.dialogCaseFormRef.resetFields()
          this.$message.success('新增测试成功')
          this.loadCaseList()
        }
      } catch (error) {
        this.$message.warning('信息校验未通过222')
      }
    },
    handleEdit (rowData) {
      console.log(rowData, '<---编辑的用例信息')
      this.isAdd = false
      this.dialogFormVisible = true
      this.currentId = rowData.id
      this.dialogCaseForm.case_name = rowData.case_name
      this.dialogCaseForm.request_protocal = rowData.protocal
      this.dialogCaseForm.request_method = rowData.method
      this.dialogCaseForm.request_host = rowData.host
      this.dialogCaseForm.request_path = rowData.path
      this.dialogCaseForm.request_port = rowData.port
      this.dialogCaseForm.request_params = rowData.params
      this.dialogCaseForm.request_body = rowData.body
      this.dialogCaseForm.predict = rowData.predict
      this.dialogCaseForm.description = rowData.description
    },
    async processEdit () {
      try {
        const EditObj = {
          id: this.currentId,
          project_id: this.$store.state.projectId,
          suite_id: this.$store.state.suiteId,
          case_name: this.dialogCaseForm.case_name,
          request_protocal: this.dialogCaseForm.request_protocal,
          request_method: this.dialogCaseForm.request_method,
          request_host: this.dialogCaseForm.request_host,
          request_path: this.dialogCaseForm.request_path,
          request_port: this.dialogCaseForm.request_port,
          request_body: this.dialogCaseForm.request_body,
          predict: this.dialogCaseForm.predict,
          description: this.dialogCaseForm.description,
          request_headers: { 'Content-Type': 'application/json' }
        }
        const { data: res } = await EditCase(EditObj)
        console.log(res, '<--编辑测试用例返回信息')
        if (res.status === 1) {
          this.dialogFormVisible = false
          this.currentId = ''
          this.dialogCaseForm.case_name = ''
          this.dialogCaseForm.request_protocal = ''
          this.dialogCaseForm.request_method = ''
          this.dialogCaseForm.request_host = ''
          this.dialogCaseForm.request_path = ''
          this.dialogCaseForm.request_port = ''
          this.dialogCaseForm.request_body = ''
          this.dialogCaseForm.predict = ''
          this.dialogCaseForm.description = ''
          this.$message.success('编辑测试用例成功')
          this.loadCaseList()
        }
      } catch (error) {
        this.$message.warning('校验信息失败')
      }
    },
    async handleDis (rowData) {
      const DisObj = {
        suite_id: this.$store.state.suiteId,
        id: rowData.id
      }
      console.log(DisObj, '<--启用/禁用请求信息')
      const { data: res } = await DisCase(DisObj)
      console.log(res, '<--启用/禁用测试套件')
      if (res.status === 1) {
        this.$message.success(res.data.status === '0' ? '禁用成功' : '启用成功')
        this.loadCaseList()
      }
    },
    // 执行测试用例
    async deBugCase () {
      const deBugObj = {
        project_id: this.$store.state.projectId,
        project_name: '写死项目名',
        suite_id: this.$store.state.suiteId,
        suite_name: '写四套件名',
        env_id: '1',
        caseid_list: this.multipleSelections,
        executionMode: '手动执行用例'
      }
      if (this.multipleSelections.length === 1) {
        const { data: res } = await RunCase(deBugObj)
        console.log(res, '<-- 运行测试用例结果')
        if (res.status === 1) {
          console.log(res, '<-- 用例数为1时')
          this.$store.commit('saveReportInfo', res.data)
          this.$router.push('/report/info')
        } else this.$message.error(res.message)
      } else if (this.multipleSelections.length > 1) {
        const { data: res } = await RunCase(deBugObj)
        console.log(res, '<-- 运行测试用例结果')
        if (res.status === 1) {
          this.$message.success('运行测试用例成功，请在测试报告中查看运行结果')
          this.loadCaseList()
        } else this.$message.error(res.message)
      } else {
        this.$message.warning('请选择要执行的用例')
      }
    },
    async handleDelUpload (options) {
      console.log(options, '<--没有')
      // 使用 form/data 类型 请求数据 并且组装请求数据
      const fd = new FormData()
      fd.append('file', options.file)
      fd.append('project_id', this.$store.state.projectId)
      fd.append('operation', 'import')
      fd.append('suite_id', this.$store.state.suiteId)

      // 发送请求
      const { data: res } = await ImportCase(fd)
      console.log(res, '<--上传后的返回数据')
      if (res.status === 1) {
        this.$message.success('导入测试用例成功')
        this.loadCaseList()
      }
    },
    async handleExport () {
      // 组装请求数据，类型为 form-data
      const fd = new FormData()
      fd.append('case_id_list', this.multipleSelections)
      fd.append('project_id', this.$store.state.projectId)
      fd.append('operation', 'export')
      fd.append('suite_id', this.$store.state.suiteId)
      // 发送请求
      const { data: res } = await ExportCase(fd)
      // 将返回的二进制文件处理成本地文件
      const blob = new Blob([res], {
        type: 'application/vnd.ms-excel;charset=utf-8'
      })
      const objUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objUrl
      link.download = '测试用例下载.xls' // 给下载后的文件命名
      link.click() // 通过主动调用 click 方法来模拟用户点击了界面上的 a 链接
      window.URL.revokeObjectURL(blob)
      // this.$refs.multipleTable.clearSelection();
    }
  }
}
</script>

<style lang="scss" scoped>
  .case-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .demo-form-inline {
    height: 40px;
  }
  .upload-case {
    display: inline-block;
    margin: 0 10px;
  }
  .el-pagination {
    padding: 0px 0;
  }
</style>
