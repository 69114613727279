import request from '@/utils/request.js'

export const urlPath = '/api/'

// 查询接口列表
export const queryApiBaseInfoList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'apiBaseInfo',
    params: {
      ...data
    }
  })
}

// 通过ID查询接口信息
export const queryApiBaseInfoByID = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'apiBaseInfo',
    params: {
      ...data
    }
  })
}

// 添加API信息
export const addApiBaseInfo = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'apiBaseInfo',
    data
  })
}

// 编辑API信息
export const editApiBaseInfo = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'apiBaseInfo',
    data
  })
}

// 删除API信息
export const deleteApiBaseInfo = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'apiBaseInfo',
    data
  })
}
