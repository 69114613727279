import request from '@/utils/request.js'
import { urlPath } from './apiBaseInfo'

// 查询测试用例列表
export const QueryCaseList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'case',
    params: {
      ...data
    }
  })
}

// 删除测试用例
export const DelCase = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'case',
    data
  })
}

// 新增测试用例
export const AddCase = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'case',
    data
  })
}

// 禁用/启用 测试用例
export const DisCase = (data) => {
  return request({
    method: 'PATCH',
    url: urlPath + 'case',
    data
  })
}

// 依据名称查询测试用例
export const SearchCase = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'case',
    params: {
      ...data
    }
  })
}

// 编辑测试用例
export const EditCase = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'case',
    data
  })
}

// 导入测试用例
export const ImportCase = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'case',
    data
  })
}

// 导出测试用例
export const ExportCase = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'case',
    responseType: 'blob',
    data
  })
}

// 运行测试用例
export const RunCase = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'run/testcase',
    data
  })
}
